<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.name"
        label="搜索场馆"
        v-bind="layout"
        placeholder="请输入场馆名称"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="id"
        label="场馆分组"
        inner-search
        placeholder="全部分组"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="发布状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="statusData"
      />
    </BjSearch>
    <div :class="$style.table">
      <a-row :class="$style.header">
        <a-col span="4" :class="$style.title"> 场馆列表 </a-col>
        <a-col span="20" :class="$style.btns">
          <div :class="$style.badge">
            <recycle :count="recycleTotal" @refresh="refresh" />
          </div>
          <div :class="$style.bor" />
          <BjButton type="primary" @click="onAdd()">
            <i class="ri-add-line left" />
            添加场馆
          </BjButton>
        </a-col>
      </a-row>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #cover="item">
          <img :class="$style.cover" :src="item.cover.cover" />
        </template>
        <template #status="item">
          <BjTag :type="item.status ? 'primary' : 'default'">{{ item.status === 1 ? '已发布' : '未发布' }}</BjTag>
        </template>
        <template #info="item">
          <div>电话：{{ item.contact_phone }}</div>
          <div>地址：{{ item.location }}</div>
        </template>
        <template #action="item">
          <bj-link type="primary" @click="onPreview(item)"> 预览 </bj-link>
          <bj-link type="primary" @click="onEdit(item)"> 编辑 </bj-link>
          <bj-link type="primary" @click="onService(item)"> 服务 </bj-link>
          <a-popconfirm title="确定要删除场馆吗？" @confirm="onDelete(item.id)">
            <bj-link type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { appVenueService } from '@/service'

import recycle from './recycle.vue'

const service = new appVenueService()

export default {
  name: 'Home',
  components: {
    recycle,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      groupData: [],
      statusData: [
        {
          name: '全部状态',
          id: '',
        },
        {
          name: '已发布',
          id: 1,
        },
        {
          name: '未发布',
          id: 0,
        },
      ],
      search: {
        name: '',
        status: '',
        group_id: '',
      },
      data: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      recycleTotal: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '场馆封面',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '场馆名称',
          dataIndex: 'name',
          ellipsis: true,
        },
        {
          title: '场馆分组',
          customRender: item => item.group.name || '-',
        },
        {
          title: '场馆信息',
          scopedSlots: {
            customRender: 'info',
          },
        },
        {
          title: '发布状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
    this.getType()
    this.getRecycle()
  },
  methods: {
    async getRecycle() {
      const { data } = await service.getRecycle()
      this.recycleTotal = data.total
    },
    refresh() {
      this.getList()
      this.getRecycle()
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getList({
          ...this.search,
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getType() {
      try {
        const { data } = await service.getGroup()
        this.groupData = [{ name: '全部分组', id: '' }].concat(data)
      } catch (e) {}
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    onAdd() {
      this.$router.push({
        name: 'appVenueManageAdd',
      })
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'app_venue',
          id: item.id,
        },
      })
    },
    async onDelete(id) {
      await service.delete({
        ids: [id],
      })
      this.$message.success('删除成功')
      this.page = 1
      this.refresh()
    },
    onEdit(item) {
      this.$router.push({
        name: 'appVenueManageAdd',
        query: {
          id: item.id,
        },
      })
    },
    onService(item) {
      this.$router.push({
        name: 'appVenueManageService',
        query: {
          id: item.id,
        },
      })
    },
    init() {
      this.search = {
        name: '',
        status: '',
        group_id: '',
      }
      this.page = 1
      this.getList()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    min-height: calc(100vh - 252px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .header {
      padding: 0 0 20px 0;

      .title {
        color: #000;
        font-size: 16px;
      }

      .btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bor {
          width: 1px;
          height: 20px;
          margin-right: 20px;
          border-right: 1px solid #eee;
        }

        .badge {
          width: 100px;
          height: 30px;
          margin-right: 20px;
          padding-right: 18px;
          color: #000;
          line-height: 30px;
          cursor: pointer;

          i {
            position: relative;
            top: 2px;
            left: -2px;
            color: #000;
            font-size: 20px;
          }
        }
      }
    }

    .cover {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      object-fit: cover;
    }
  }
}
</style>
